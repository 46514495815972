@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}

body {
  color: #1d1d1f;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  overflow: hidden;
}

span,
button {
  cursor: pointer;
}

.roboto {
  font-family: Roboto;
}
